import React from "react";
import Head from "next/head";
import Script from "next/script";
import { Header, Footer, Main } from "@components";
//import Main from "@components/main";

import AppConfig from "src/config/AppConfig";

const Home: React.FC = () => {
  return (
    <>
      <Script
        type="text/javascript"
        src={`https://maps.googleapis.com/maps/api/js?key=${AppConfig.googleMapsApiKey}&language=en&libraries=places`}
      />
      <Header page={'home'} />
      <main>
        <Main />
      </main>
      <Footer />
    </>
  );
};

export default Home;
